import { Route, Routes } from "react-router-dom";
import Home from "./Component/Home";
import AboutUs from "./Component/AboutUs";
import Privacypolicy from "./Component/Privacypolicy";
import TermsAndConditions from "./Component/TermsAndConditions";
import RefundPolicy from "./Component/RefundPolicy";
// import NotFound from "./Component/NotFound"; // Import a NotFound component

function App() {
  return (
    <>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/privacypolicy" element={<Privacypolicy />} />
        <Route path="/termsAndConditions" element={<TermsAndConditions />} />
        <Route path="/refundPolicy" element={<RefundPolicy />} />
       

      </Routes>
    </>
  );
}

export default App;

import React, { useState, useRef } from 'react';
import bg1 from '../Assets/Images/page-first-bg.png';
import bg2 from '../Assets/Images/page-one-bg.png';
import bg3 from '../Assets/Images/page-two-bg.png';
import bg4 from '../Assets/Images/page-three-bg.png';
import bg5 from '../Assets/Images/page-four-bg.png';
import bg6 from '../Assets/Images/page-five-bg.png';
import gif from '../Assets/Images/banner-one-bg.gif';
import logo from '../Assets/Images/logo.png';
import colicon1 from '../Assets/Images/collaboration-1.svg'
import colicon2 from '../Assets/Images/collaboration-2.svg'
import colicon3 from '../Assets/Images/promotion.png'
import colicon4 from '../Assets/Images/collaboration-4.svg'
import mobile from '../Assets/Images/Frame 5.png'
import videoicon from '../Assets/Images/play.svg'
import mobilebg from '../Assets/Images/mobailbg.svg'
import android from '../Assets/Images/logotype.png'
import apple from '../Assets/Images/apple (2).png'
import { MdOutlineArrowBackIos } from "react-icons/md";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import user1 from '../Assets/Images/user-1.png'
import user2 from '../Assets/Images/user-2.png'
import user3 from '../Assets/Images/user-3.png'
import user4 from '../Assets/Images/user-6.png'
import user5 from '../Assets/Images/user-4.png'
import How from '../Assets/Images/start.PNG'
import { FaFacebook, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa6';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import '../Assets/Css/Style.css';
import { Pagination, Navigation, Mousewheel } from 'swiper/modules';
import { Link } from 'react-router-dom';



const Home = () => {
    const testimonials = [
        {
            name: "Bindu Garg",
            image: user1,
            testimonial: "I have been following your page for not long but I am addicted to it. I look forward to the informative posts every day. It really helps to understand the Instagram algorithm better. By using tips and tricks I have been able to increase the reach of my followers. Hashtags given by you are really working well for me. Gratitude!!!!",
            bgImage: bg5, // Assuming you have an imported bg5 image variable
        },
        {
            name: "Bindu Garg",
            image: user2,
            testimonial: "I have been following your page for not long but I am addicted to it. I look forward to the informative posts every day. It really helps to understand the Instagram algorithm better. By using tips and tricks I have been able to increase the reach of my followers. Hashtags given by you are really working well for me. Gratitude!!!!",
            bgImage: bg5, // Assuming you have an imported bg5 image variable
        },
        {
            name: "Bindu Garg",
            image: user3,
            testimonial: "I have been following your page for not long but I am addicted to it. I look forward to the informative posts every day. It really helps to understand the Instagram algorithm better. By using tips and tricks I have been able to increase the reach of my followers. Hashtags given by you are really working well for me. Gratitude!!!!",
            bgImage: bg5, // Assuming you have an imported bg5 image variable
        },
        {
            name: "Bindu Garg",
            image: user4,
            testimonial: "I have been following your page for not long but I am addicted to it. I look forward to the informative posts every day. It really helps to understand the Instagram algorithm better. By using tips and tricks I have been able to increase the reach of my followers. Hashtags given by you are really working well for me. Gratitude!!!!",
            bgImage: bg5, // Assuming you have an imported bg5 image variable
        },
        {
            name: "Bindu Garg",
            image: user5,
            testimonial: "I have been following your page for not long but I am addicted to it. I look forward to the informative posts every day. It really helps to understand the Instagram algorithm better. By using tips and tricks I have been able to increase the reach of my followers. Hashtags given by you are really working well for me. Gratitude!!!!",
            bgImage: bg5, // Assuming you have an imported bg5 image variable
        },
        // Add more testimonials as needed
    ];


    const contactInfo = [
        // {
        //     title: 'CALL',
        //     details: [
        //         '+91 7000830437',
        //     ],
        // },
        {
            title: 'EMAIL',
            link: [
                'admin@hookstep.net',
            ],
        },
        // {
        //     title: 'ADDRESS',
        //     details: [
        //         '001 Mayuransh Elanza near Nr. Parekhs Hospital, Shyamal Crossroad, 132 Feet Ring Rd, Satellite, Ahmedabad, Gujarat 380015'
        //     ],
        // },
    ];
    const swiperRef = useRef(null); // Ref for the Swiper instance

    const [activeIndex, setActiveIndex] = useState(0);

    const handleDownloadClick = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(3); // Slide to the fourth SwiperSlide (index 3)
        }
    };

    const handleLogoClick = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(0); // Slide to the first SwiperSlide (index 0)
        }
    };

    const sliderRef = useRef(null); // Ref for the Swiper instance

    const handlePrevClick = () => {
        if (sliderRef.current && sliderRef.current.swiper) {
            sliderRef.current.swiper.slidePrev();
        }
    };

    const handleNextClick = () => {
        if (sliderRef.current && sliderRef.current.swiper) {
            sliderRef.current.swiper.slideNext();
        }
    };


    return (
        <>
            <nav className="px-8 pt-6 fixed top-0 w-full z-10">
                <div className="flex justify-between items-center">
                    <div className="text-2xl font-bold flex items-center gap-4 cursor-pointer" onClick={handleLogoClick}>
                        <img src={logo} alt="Logo" className="md:w-20 w-14" />
                        <p className="uppercase text-[#3552d1] hidden md:flex">Hookstep</p>
                    </div>

                    <div className="md:flex items-center gap-10">
                        <div className="w-full">
                            <button
                                className="uppercase bg-[#3552d1] text-white px-4 py-2 text-xs md:text-lg font-bold flex items-center w-full justify-center"
                                onClick={handleDownloadClick}
                            >
                                <p className='md:pt-2'>Download</p>
                            </button>
                        </div>

                        {/* Icons */}
                        <div className="md:flex gap- hidden items-center">
                            <div className="hover:border-2 w-12 p-2 rounded-full hover:border-[#3552d1]">
                                <FaFacebook className='text-3xl hvr-grow'/>
                            </div>
                            <div className="hover:border-2 w-12 p-2 rounded-full hover:border-[#3552d1]">
                                <FaInstagram className='text-3xl hvr-grow'/>
                            </div>
                            <div className="hover:border-2 w-12 p-2 rounded-full hover:border-[#3552d1]">
                                <FaLinkedinIn className='text-3xl hvr-grow'/>
                            </div>
                            <div className="hover:border-2 w-12 p-2 rounded-full hover:border-[#3552d1]">
                                <FaYoutube className='text-3xl hvr-grow'/>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            <Swiper
                ref={swiperRef}
                direction={'vertical'}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination, Mousewheel]}
                mousewheel={true}
                className="mySwiper"
                onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
            >
                {/* Slide 1 */}
                <SwiperSlide id="slide1">
                    <div
                        className="bg-cover bg-center h-screen flex items-center mt-8 justify-center text-center"
                        style={{ backgroundImage: `url(${bg1})`, backgroundSize: '100% 100%' }}
                    >
                        <div className="flex flex-col md:flex-row container items-center mx-auto px-8  justify-evenly">
                            <div className="flex flex-col justify-center h-full w-full md:w-10/12 lg:w-1/2">
                                <h1 className="text-xl  md:text-4xl lg:text-6xl font-bold md:mb-4 text-left uppercase">Let's</h1>
                                <h1 className="text-xl  md:text-4xl lg:text-6xl font-bold md:mb-4 text-left text-[#3552d1] uppercase">Collaborate</h1>

                                <p className="text-xs md:text-sm mb-2 lg:mb-8 text-left">
                                    Now, growing on social media is easier. Ask fellow creators to give you a shoutout for your amazing content. Not only that, you get professional help like video editing, photography, etc. at your fingertips so that you concentrate on your core talent.
                                </p>
                                <div className="grid grid-cols-2 md:grid-cols-2 gap-4 mb-8">
                                    <div className="text-left space-y-2">
                                        <div className="border-2 w-fit p-3 rounded-full border-[#3552d1]">
                                            <img src={colicon1} alt="Shoutout Icon"
                                                style={{
                                                    filter: 'invert(35%) sepia(100%) saturate(10000%) hue-rotate(200deg) brightness(95%) contrast(95%)',
                                                }}
                                            />
                                        </div>
                                        <h2 className="text-sm md:text-lg font-bold text-[#3552d1]">Get Shoutout</h2>
                                        <p className="text-sm md:text-sm font-medium">
                                            Get a shoutout from your fellow creators on their stories, posts, and videos.
                                        </p>
                                    </div>
                                    <div className="text-left space-y-2">
                                        <div className="border-2 w-fit p-3 rounded-full border-[#3552d1]">
                                            <img src={colicon2} alt="Professional Help Icon"
                                                style={{
                                                    filter: 'invert(35%) sepia(100%) saturate(10000%) hue-rotate(200deg) brightness(95%) contrast(95%)',
                                                }}
                                            />
                                        </div>
                                        <h2 className="text-sm md:text-lg font-bold text-[#3552d1]">Professional Help</h2>
                                        <p className="text-sm md:text-sm font-medium">
                                            Get video editing, photo touchups, photoshoot help on your content.
                                        </p>
                                    </div>
                                    <div className="text-left space-y-2">
                                        <div className="border-2 w-fit p-3 rounded-full border-[#3552d1]">
                                            <img src={colicon3} alt="Create Together Icon"
                                                style={{
                                                    filter: 'invert(35%) sepia(100%) saturate(10000%) hue-rotate(200deg) brightness(95%) contrast(95%)',
                                                }}
                                            />
                                        </div>
                                        <h2 className="text-sm md:text-lg font-bold text-[#3552d1]">Create Together</h2>
                                        <p className="text-xs md:text-sm font-medium">
                                            Create content with your favorite creators on social media.
                                        </p>
                                    </div>
                                    <div className="text-left space-y-2">
                                        <div className="border-2 w-fit p-3 rounded-full border-[#3552d1]">
                                            <img src={colicon4} alt="Creators Meet Icon"
                                                style={{
                                                    filter: 'invert(35%) sepia(100%) saturate(10000%) hue-rotate(200deg) brightness(95%) contrast(95%)',
                                                }}
                                            />
                                        </div>
                                        <h2 className="text-sm md:text-lg font-bold text-[#3552d1]">Creators Meet</h2>
                                        <p className="text-sm md:text-sm font-medium">
                                            Meet creators from your city and make connections!
                                        </p>
                                    </div>
                                </div>
                                <div className="flex gap-4">
                                    <button className="uppercase bg-[#3552d1] text-white  md:px-4 py-2 text-xs md:text-lg font-bold w-1/2 md:w-1/2 flex items-center justify-center">
                                        <p className="md:pt-2"> Let's Connect</p>
                                    </button>
                                    <button className="uppercase text-[#3552d1]  md:px-4 py-2 text-xs lg:text-lg font-bold w-1/2 md:w-1/2 flex items-center justify-center gap-2">
                                        <img src={videoicon} alt="Video Icon"
                                            style={{
                                                filter: 'invert(35%) sepia(100%) saturate(10000%) hue-rotate(200deg) brightness(95%) contrast(95%)',
                                            }} />
                                        <p className="md:pt-2"> Coming Soon...</p>
                                    </button>
                                </div>
                            </div>
                            <div className=" md:flex hidden  flex-col  lg:justify-center  items-center lg:h-screen">
                                <img src={mobile} alt="Mobile App" className="lg:w-3/4 w-1/4 md:w-1/2 xl:w-full" />
                                <div className="mt-2 mb-8 text-center">
                                    <p className="text-sm md:text-xl font-medium">App for</p>
                                    <p className="text-[#3552d1] text-lg md:text-2xl font-semibold">CREATORS</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='md:flex hidden'>
                        <img src={mobilebg} alt="" className='absolute  w-1/5 top-[30%] right-[2%] md:w-1/4 md:top-[24%] md:right-[2%]  lg:top-[15%] lg:right-[3%] lg:w-1/4 xl:top-[12%] xl:right-[8%] xl:w-1/4 2xl:top-[10%] 2xl:right-[12%] 2xl:w-1/6' />
                    </div>
                </SwiperSlide>

                <SwiperSlide id="slide2">
                    <div className='md:flex h-full items-center '>
                        <div
                            className="collaborate bg-cover bg-left w-full md:w-1/2   flex items-center justify-center md:h-full h-1/2 "
                            style={{ backgroundImage: `url(${bg2})` }}
                        >
                            <div className='border-l-8 border-black flex flex-col justify-end place-items-end'>
                                <div className='uppercase ms-6'>
                                    <h1 className='text:2xl md:text-4xl xl:text-7xl  font-extrabold space-x-2 tracking-wide'>Empowering</h1>
                                    <h1 className='text:2xl md:text-4xl xl:text-7xl text-[#3552d1] font-extrabold tracking-wide'>Social media </h1>
                                    <h1 className='text:2xl md:text-4xl xl:text-7xl  font-extrabold tracking-wide'>Influencers</h1>
                                </div>
                            </div>
                        </div>

                        <div className="md:w-3/4 w-full  flex justify-center items-center md:h-full h-1/2">
                            <img
                                src={gif}
                                alt="Animated banner"
                                className='h-full  w-full object-cover'
                            />
                        </div>
                    </div>

                </SwiperSlide>

                {/* Slide 3 */}
                <SwiperSlide id="slide3">
                    <div
                        className="bg-cover bg-center h-screen flex flex-col items-center justify-center"
                        style={{ backgroundImage: `url(${bg3})` }}
                    >
                        <div className='flex gap-4 text-left uppercase mb-4'>
                            <p className='text:2xl md:text-4xl xl:text-7xl tracking-wide'>How do</p>
                            <p className='text:2xl md:text-4xl xl:text-7xl text-[#3552d1]  tracking-wide'>we do it ?</p>
                        </div>
                        <div className='flex flex-col items-center justify-center w-3/5 h-3/5'>
                            <img src={How} alt="" className='h-full' />
                        </div>
                    </div>

                </SwiperSlide>

                {/* Slide 4 */}
                <SwiperSlide id="slide4">
                    <div
                        className="bg-cover bg-center h-screen flex flex-col items-center justify-center px-8"
                        style={{ backgroundImage: `url(${bg4})` }}
                    >
                        <h1 className="text:2xl md:text-4xl xl:text-7xl font-bold mb-4 text-left text-[#3552d1]">Download the App</h1>
                        <p className="text-xs md:text-lg mb-8 text-left">Stay connected and never miss an update. Download the Hookstep app now and enjoy seamless access to all our features!</p>
                        <div className="md:flex justify-around">

                            <div className="md:p-6 text-center flex flex-col place-items-center">

                                <div className="flex gap-4 justify-center w-full">
                                    <button className="rounded-2xl text-white font-bold mb-4 py-4 px-4 border w-full border-black bg-black flex items-center justify-center gap-2">
                                        <img src={android} alt="" className='md:w-20 w-10' />
                                        <div className=''>
                                            <p className='uppercase md:text-sm text-xs'>Get in on</p>
                                            <p className='text:2xl lg:text-4xl'>Google Play</p>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div className="md:p-6 text-center flex flex-col place-items-center">

                                <div className="flex gap-4 justify-center w-full">
                                    <button className="rounded-2xl text-white font-bold mb-4 py-4 px-4 border w-full border-black bg-black flex items-center justify-center gap-2">
                                        <img src={apple} alt="" className='md:w-20 w-10' />
                                        <div>
                                            <p className='md:text-sm text-xs'>Download on the</p>
                                            <p className='text:2xl lg:text-4xl'>App Store</p>
                                        </div>
                                    </button>
                                </div>
                            </div>


                        </div>
                    </div>
                </SwiperSlide>

                {/* Slide 5 */}
                <SwiperSlide id="slide5">
                    <div
                        className="bg-cover bg-center h-screen flex items-center justify-center text-center px-8"
                        style={{ backgroundImage: `url(${testimonials[0].bgImage})` }} // Dynamically set background
                    >
                        <div className='lg:container mx-auto lg:w-3/5 w-full'>
                            <div className='lg:flex gap-4 justify-between items-center mb-4'>
                                <div className='flex gap-4 justify-center'>
                                    <h1 className="text:2xl md:text-4xl xl:text-5xl 2xl:7xl font-bold text-left uppercase">Hookstep</h1>
                                    <h1 className="text:2xl md:text-4xl xl:text-5xl 2xl:7xl font-bold text-left text-[#3552d1] uppercase">testimonial</h1>
                                </div>
                                <div className='flex justify-center'>
                                    <button
                                        onClick={handlePrevClick}
                                        className="bg-black border border-black px-2 py-2 md:px-6 md:py-4 lg:py-4 font-bold text-white flex items-center"
                                    >
                                        <MdOutlineArrowBackIos />
                                    </button>
                                    <button
                                        onClick={handleNextClick}
                                        className="bg-[#3552d1] border border-black px-2 py-2 md:px-6 md:py-4 lg:py-4 font-bold text-white flex items-center"
                                    >
                                        <MdOutlineArrowForwardIos />
                                    </button>
                                </div>
                            </div>
                            <Swiper
                                ref={sliderRef}
                                slidesPerView={1}
                                loop={true}
                                spaceBetween={30}
                                pagination={false}
                                navigation={false}
                                modules={[Pagination, Navigation]}
                                className="swiper-container"
                            >
                                {testimonials.map((testimonial, index) => (
                                    <SwiperSlide key={index} className="swiper-slide">
                                        <div className="mx-auto border-2 border-black p-4">
                                            <p className="md:text-xl text-sm text-left">
                                                {testimonial.testimonial}
                                            </p>
                                            <div className='flex gap-4 place-items-center'>
                                                <img src={testimonial.image} alt={testimonial.name} className="rounded-full w-20 h-20 mb-4" />
                                                <h3 className="text-lg font-bold mb-2 text-left">{testimonial.name}</h3>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                            <div className='flex gap-4 mt-4 '>
                                <h1 className="text:2xl md:text-4xl xl:text-5xl 2xl:7xl font-bold text-left uppercase">Our</h1>
                                <h1 className="text:2xl md:text-4xl xl:text-5xl 2xl:7xl font-bold text-left text-[#3552d1] uppercase">satisfied Influencers</h1>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>


                {/* Slide 6 */}
                <SwiperSlide id="slide6">
                    <div
                        className="bg-cover bg-center h-screen flex flex-col items-center justify-center "
                        style={{ backgroundImage: `url(${bg6})` }}
                    >
                        <div className="flex flex-col h-3/4 md:h-full">
                            <div className="container mx-auto px-4 md:py-16  flex-grow flex flex-col justify-center">
                                <h1 className="text:2xl md:text-4xl xl:text-5xl 2xl:6xl font-bold mb-4 text-left uppercase">Contact</h1>
                                <h1 className="text:2xl md:text-4xl xl:text-5xl 2xl:6xl font-bold mb-4 text-left text-[#3552d1] uppercase">Information</h1>
                                <p className="text-xs md:text-sm lg:text-xl md:mb-12 mb-4 w-3/5 font-medium">
                                    Empowering Social Media Influencers And Brands Can Now Get
                                    Answers To Their Identified Needs And Discover Why Hookstep Influencer Is
                                    The Ultimate Solution For Your Corporation.
                                </p>
                                <div className="grid grid-cols-1 md:grid-cols-3 md:gap-8 gap-4">
                                    {contactInfo.map((info, index) => (
                                        <div key={index}>
                                            <h3 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl text-lg font-bold text-[#3552d1] mb-4">
                                                {info.title}
                                            </h3>
                                            {/* Check if details exist and render them */}
                                            {/* {info.details?.map((detail, idx) => (
                                                <p key={idx} className="2xl:text-3xl xl:text-2xl lg:text-xl md:text-lg text-sm font-medium">
                                                    {detail}
                                                </p>
                                            ))} */}
                                            {/* Check if link exists and render it */}
                                            {info.link?.map((link, idx) => (
                                                <a
                                                    key={idx}
                                                    href={`mailto:${link}`} // Assuming it's an email link
                                                    className="2xl:text-3xl xl:text-2xl lg:text-xl md:text-lg text-sm font-medium"
                                                >
                                                    {link}
                                                </a>
                                            ))}
                                        </div>
                                    ))}
                                </div>


                            </div>
                        </div>
                        <div className="flex item-end justify-center py-4">
                            <div className="text-center">
                                <div className="flex justify-center ">
                                    <Link to="/aboutus" className="text-xs lg:text-xl border-r-2 px-1 md:px-6 border-black">About Us</Link>
                                    <Link to="/privacypolicy" className="text-xs lg:text-xl border-r-2 px-1 md:px-6 border-black">Privacy Policy</Link>
                                    <Link to="/termsAndConditions" className="text-xs lg:text-xl border-r-2 px-1 md:px-6 border-black">Terms of Service</Link>
                                    {/* <Link to="/refundPolicy" className="text-xl border-r-2 px-6 border-black">Refund policy</Link> */}
                                    <p className="text-xs lg:text-xl px-1 md:px-4 uppercase">© 2024 HOOKSTEP</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </SwiperSlide>

            </Swiper>


            <div className=' top-[80%] -left-16 md:flex hidden  fixed z-10 -rotate-90'>
                <a href="https://admin.hookstep.net"><p className='text-black text-lg font-bold '>Admin@HookStep.net</p></a>
            </div>
        </>
    );
};

export default Home;
